import React, { useState, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import image1 from '../assets/carrusel/Flores1.jfif';
import image2 from '../assets/carrusel/Flores2.jfif';
import image3 from '../assets/carrusel/Flores3.jfif';
import image4 from '../assets/carrusel/Flores4.jfif';

const Carrusel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [bgColorIndex, setBgColorIndex] = useState(0);

  const images = [
    { id: 1, src: image1, alt: 'Contacto' },
    { id: 2, src: image2, alt: 'Service' },
    { id: 3, src: image3, alt: 'as' },
    { id: 4, src: image4, alt: 'asss' },
  ];

  const bgColors = [
    'from-pink-300 to-pink-200',
    'from-pink-200 to-pink-200',
  ];

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 7000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  useEffect(() => {
    const colorInterval = setInterval(() => {
      setBgColorIndex((prevIndex) => (prevIndex + 1) % bgColors.length);
    }, 3000);
    return () => clearInterval(colorInterval);
  }, []);

  return (
    <div className={`relative mx-auto p-4 mt-4 bg-gradient-to-r ${bgColors[bgColorIndex]} text-green-700 transition-colors duration-700`} style={{ height: 'calc(10cm + 4rem)' }}>
      <div className="relative overflow-hidden max-w-3xl mx-auto h-[7.8cm]">
        <img
          src={images[currentSlide].src}
          alt={images[currentSlide].alt}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="absolute top-1/2 left-4 right-4 flex justify-between items-center transform -translate-y-1/2">
        <button
          onClick={prevSlide}
          className="text-white bg-green-500 rounded-full p-3 sm:p-4 text-3xl sm:text-4xl border-2 border-white focus:outline-none shadow-lg hover:bg-green-700 transition-transform transform hover:scale-110"
        >
          <IoIosArrowBack />
        </button>
        <button
          onClick={nextSlide}
          className="text-white bg-green-500 rounded-full p-3 sm:p-4 text-3xl sm:text-4xl border-2 border-white focus:outline-none shadow-lg hover:bg-green-700 transition-transform transform hover:scale-110"
        >
          <IoIosArrowForward />
        </button>
      </div>
      <div className="flex flex-col items-center text-center bg-gradient-to-r">
        <a href="https://wa.me/573226473899" className="text-green-700 text-lg font-semibold mb-2" target="_blank" rel="noopener noreferrer">
          WhatsApp: +57 322-647-3899
        </a>
        <a href="mailto:lilianahernandez1820@gmail.com" className="text-green-700 text-lg font-semibold" target="_blank" rel="noopener noreferrer">
          Email: lilianahernandez1820@gmail.com
        </a>
        <p className="text-green-700 text-lg font-semibold">Dirección : Cra 35 # 44 - 18 - B/quilla - Atlantico</p>
      </div>
    </div>
  );
};

export default Carrusel;
