import React, { useEffect } from 'react';
import Image1 from '../assets/Productos.jfif';
import WhatsappButtonFlowers from '../components/WhatsappButtonFlowers.js';
import './WhatsappButtonFlowers.css';
import './FlowersProductos.css'; // Asegúrate de importar tu archivo CSS
import Flowers1 from '../assets/ImagenesProductos/Flowers1.PNG';
import Flowers2 from '../assets/ImagenesProductos/Flowers2.PNG';
import Flowers3 from '../assets/ImagenesProductos/Flowers3.PNG';
import Flowers4 from '../assets/ImagenesProductos/Flowers4.PNG';
import Flowers5 from '../assets/ImagenesProductos/Flowers5.PNG';
import Flowers6 from '../assets/ImagenesProductos/Flowers6.PNG';
import Flowers7 from '../assets/ImagenesProductos/Flowers7.PNG';
import Flowers8 from '../assets/ImagenesProductos/Flowers8.PNG';
import Flowers9 from '../assets/ImagenesProductos/Flowers9.PNG';
import Flowers10 from '../assets/ImagenesProductos/Flowers10.PNG';
import Flowers11 from '../assets/ImagenesProductos/Flowers11.PNG';
import Flowers12 from '../assets/ImagenesProductos/Flowers12.PNG';
import Flowers13 from '../assets/ImagenesProductos/Flowers13.PNG';
import Flowers14 from '../assets/ImagenesProductos/Flowers14.PNG';
import Flowers15 from '../assets/ImagenesProductos/Flowers15.PNG';
import Flowers16 from '../assets/ImagenesProductos/Flowers16.PNG';
import Flowers17 from '../assets/ImagenesProductos/Flowers17.PNG';
import Flowers18 from '../assets/ImagenesProductos/Flowers18.PNG';
import Flowers19 from '../assets/ImagenesProductos/Flowers19.PNG';
import Flowers20 from '../assets/ImagenesProductos/Flowers20.PNG';
import Flowers21 from '../assets/ImagenesProductos/Flowers21.PNG';
import Flowers22 from '../assets/ImagenesProductos/Flowers22.PNG';
import Flowers23 from '../assets/ImagenesProductos/Flowers23.PNG';
import Flowers24 from '../assets/ImagenesProductos/Flowers24.PNG';
import Flowers25 from '../assets/ImagenesProductos/Flowers25.PNG';
import Flowers26 from '../assets/ImagenesProductos/Flowers26.PNG';


function Productos() {
  const bgColors = [
    'from-pink-550 ', // De un rosado más intenso a verde claro

  ];
  return (





    <div className="flex flex-col items-center text-center bg-gradient-to-r from-white to-pink-200 transition-colors duration-700 w-full">
      <div className="text-center mb-8">
        <h2 className="text-5xl font-serif font-bold text-pink-500">Productos</h2>
        <p className="font-serif text-center text-black text-2xl w-full md:w-3/4 mx-auto mt-4">
          Regala un toque de alegría y amor con nuestros hermosos ramos de flores.
          Perfectos para cualquier ocasión. ¡Entrega rápida y garantizada!
        </p>
      </div>

      <div className="flex flex-wrap justify-center gap-[3cm]"> {/* Ajusta el espaciado aquí */}
        {/* Primer bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers1} className="ImagenFlowers1   rounded-md mb-4" alt="Rosas y girasoles" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Rosas y girasoles</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">280.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>

        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers2} className="ImagenFlowers2   rounded-md mb-4" alt="Ramo exotico y peluche" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black ">Ramo exotico y peluche</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">210.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>



        {/* Primer bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers3} className="ImagenFlowers3   rounded-md mb-4" alt="Media luna de rosas" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Media luna de rosas</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">190.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>

        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers4} className="ImagenFlowers4   rounded-md mb-4" alt="Ramos en rosas bouquet" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Ramos en rosas bouquet</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">130.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>




        {/* Primer bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers5} className="ImagenFlowers5   rounded-md mb-4" alt="Hortencias y rosas" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Hortencias y rosas</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">100.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>

        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers6} className="ImagenFlowers6   rounded-md mb-4" alt="Lirios y rosas" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Lirios y rosas</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">250.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>








        {/* Primer bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers7} className="ImagenFlowers7   rounded-md mb-4" alt="Amanecer floral" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Amanecer floral </p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">140.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>

        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers8} className="ImagenFlowers8   rounded-md mb-4" alt="Encanto rosado" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Encanto rosado</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">300.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>


        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers9} className="ImagenFlowers9   rounded-md mb-4" alt="Amor soleado" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Amor soleado</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">175.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>


          </div>

        </div>






        {/* Primer bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers10} className="ImagenFlowers10   rounded-md mb-4" alt="Corazon de recuerdo" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Corazon de recuerdo</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">550.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>

        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers11} className="ImagenFlowers11   rounded-md mb-4" alt="Ronda de Recuerdo" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Ronda de Recuerdo</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">190.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>








        {/* Primer bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers12} className="ImagenFlowers12   rounded-md mb-4" alt="Amanecer sereno" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Amanecer sereno</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">250.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>

        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers13} className="ImagenFlowers13   rounded-md mb-4" alt="Blanco Infinito" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Blanco Infinito</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">380.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>


        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers14} className="ImagenFlowers14   rounded-md mb-4" alt="Memorias de oro" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Memorias de oro</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">160.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>


          </div>

        </div>




        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers15} className="ImagenFlowers15   rounded-md mb-4" alt="Reflejo de amor" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Reflejo de amor</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">320.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>



        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers16} className="ImagenFlowers16   rounded-md mb-4" alt="Recuerdos eternos" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Recuerdos eternos </p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">210.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>



          </div>

        </div>




        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers17} className="ImagenFlowers17   rounded-md mb-4" alt="Corazón de rosas" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Corazón de rosas</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">165.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>



        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers18} className="ImagenFlowers18   rounded-md mb-4" alt="Flores amarillas" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Flores amarillas</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">100.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>



          </div>

        </div>






        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers19} className="ImagenFlowers19   rounded-md mb-4" alt="Ramo de cumpleaños" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Ramo de cumpleaños</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">210.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>



          </div>

        </div>





        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers20} className="ImagenFlowers20   rounded-md mb-4" alt="Rosas eternas" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Rosas eternas</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">175.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>



        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers21} className="ImagenFlowers21   rounded-md mb-4" alt="Armonia encantada" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Armonia encantada</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">135.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>



          </div>

        </div>




        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers22} className="ImagenFlowers22   rounded-md mb-4" alt="Ramillete rojo" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Ramillete rojo</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">195.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>



        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers23} className="ImagenFlowers23   rounded-md mb-4" alt="Ramo multicolor" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Ramo multicolor</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">115.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>



          </div>

        </div>






        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers24} className="ImagenFlowers24   rounded-md mb-4" alt="Ramo rosado" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Ramo rosado</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">100.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>



          </div>

        </div>





        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers25} className="ImagenFlowers25   rounded-md mb-4" alt="Ramo encato rojo" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Ramo encato rojo</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">146.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>



        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers26} className="ImagenFlowers26   rounded-md mb-4" alt="Rosas inolvidables" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Rosas inolvidables</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">95.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>



          </div>

        </div>




      </div>





    </div>



  );
}

export default Productos;
